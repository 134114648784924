.login {
  .model {
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100%;

    .login-form {
      padding: 6rem;
      border-radius: 5px;
      background-color: #fff;
      width: 35rem;

      .login-form-button {
        width: 100%;
      }
    }
  }
}
.auth-main {
  height: 100%;
  background-position: right;
  background-repeat: no-repeat;
  background-size: 45% 100%;
}
.login-top-notification {
  background-color: #7776ff;
  color: #fff;
  .ant-notification-notice-message {
    color: #fff;
    font-weight: bold;
  }
}
@media screen and (max-width: 640px) {
  .auth-main::before {
    width: 100%;
  }
}
