.contract-div {
  font-family: system-ui;
  font-size: 11px;
}
.list-style {
  list-style: none !important;
}
page {
  padding: 10px !important ;
}
page {
  p {
    font-size: 11px;
    span {
      font-size: 14px;
    }
  }
  label,
  li {
    font-size: 11px;
  }

  .h1,
  h1 {
    font-size: 15px !important;
    font-weight: 700 !important;
  }
  h1,
  p,
  label {
    margin: 0;
  }
}
page {
  th {
    font-size: 11px !important;
  }

  hr {
    border: 0;
    border-top: 5px solid rgb(33 37 41);
    max-width: 700px;
  }

  span {
    font-weight: 600;
  }

  label {
    font-weight: 500 !important;
    font-size: 11px;
  }

  ul {
    list-style: none !important;
  }

  input.ani {
    width: 21px;
    height: 25px;
    text-align: center;
  }

  input:focus-visible {
    outline: -webkit-focus-ring-color auto 0px;
  }

  /* close */

  p {
    margin-top: 10px !important;
    margin-bottom: 1rem;
  }
}
///////////////////
///////////////////
///////////////////
.hr-row {
  margin-top: 20px;
  margin-bottom: 20px;
}
.contract-icon .anticon svg {
  display: inline-block;
  fill: antiquewhite;
  margin: 19px 20px 11px 20px;
}

.text-end {
  text-align: end;
}

.full_width {
  width: 100%;
}

.over_level {
  font-weight: 400;
  font-size: 11px;
}

.position {
  position: absolute;
}

.my_border {
  border: 2px solid;
}

.border-botto {
  border-bottom: 2px solid;
}

.bold {
  font-weight: 500;
}

.form_border {
  border-bottom: 1px solid !important;
  border: 0px solid #ced4da !important;
}
.border_row {
  border: 2px solid;
  padding: 16px;
}

.font_14 {
  font-size: 14px;
  line-height: 1.9;
}

.width_754 {
  max-width: 754px !important;
}

.width_730 {
  max-width: 730px !important;
}

.contract-icon .anticon svg {
  display: inline-block;
  fill: #1890ff;
  height: 26px;
  width: 37px;
  margin-top: 0px;
}

.width_40 {
  max-width: 57px;
}
.width_33 {
  max-width: 33px;
}

.pt_14 {
  padding-top: 14px;
}
.margen_left_15 {
  margin-left: -15px;
}

.weight600 {
  font-weight: 600 !important;
}
.list-none {
  list-style: none;
}
.label_left {
  position: absolute;
  left: -39px;
}
.width_21 {
  width: 21px;
}

.count {
  border: 1px solid #c3c3c3;
  width: 32px;
  text-align: end;
  padding: 0px 3px;
  height: 22px;
  margin: 0px 11px 0px 0px;
}

.form-control:focus {
  color: #495057 !important;
  background-color: #fff;
  border-color: #ffffff;
  outline: 0;
  box-shadow: 0 0 0 0rem rgb(31 30 30 / 18%);
  border: 1px solid #ced4da;
}

.form-control {
  display: block;
  width: 100%;
  height: calc(1em + 0.75rem + 2px);
  padding: 0.375rem 0.75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
.form-control::placeholder {
  font-size: 11px !important;
}

.table thead th {
  vertical-align: bottom;
  border-bottom: 2px solid #dee2e6;
  background: #ccc;
}

/*desktop viwe*/

/* new css code */

.height_120 {
  min-height: 125px;
}

@media (min-width: 1281px) {
  .doted {
    border-bottom: 1px solid !important;
    border: 0px solid #ced4da;
    width: 408px !important;
    border-style: dotted !important;
    padding-top: 14px;
    position: absolute;
    left: 42%;
  }

  .mergin_left_205 {
    margin-left: 429px;
  }
}
.full_width {
  width: 220px;
}

.width_754 h2 {
  font-size: 20px !important;
  font-weight: 700 !important;
}

.width_153 {
  width: 110px;
}
