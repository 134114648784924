.app {
  font-size: 1.4rem;
  @media screen and (max-width: 960px) {
    font-size: 1.4rem;
  }
  .aside {
    // height: 100vh;
    overflow-y: auto;
    .logo {
      height: 2.3rem;
      margin: 1rem;
      box-sizing: border-box;
      border-radius: 1rem;
      padding: 0 1rem;
      text-align: center;
      img {
        height: 75%;
      }
    }
  }

  .header {
    background-color: #1876d1;
    padding: 0 1.5rem;
    display: flex;
    position: relative;
    justify-content: space-between;
    .right {
      display: flex;
      align-items: center;
    }
  }

  .content {
    padding: 10px 18px;
    width: auto !important;
  }

  .footer {
    text-align: center;
  }
}
