::-webkit-scrollbar {
  width: 5px;
  background-color: #fff;
}
::-webkit-scrollbar:horizontal {
  height: 5px;
  background-color: #fff;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  background-color: #ccc;
}

.base-style {
  background-color: #fff;
  margin-bottom: 1rem;
  padding: 1.5rem;
  border-radius: 5px;
  box-shadow: 0px 2px 13px 0px rgba(228, 228, 228, 0.6);
}

.b-top-dark {
  border-top: 1px solid #dadada;
}
.b-right-dark {
  //border-right: 1px solid #dadada;
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
}
.b-bottom-dark {
  border-bottom: 1px solid #dadada;
}

.fullscreen {
  overflow-y: scroll;
  background-color: #fff;
}
.fullscreen-icon {
  top: 18px;
  right: 18px;
  position: absolute !important;
}
.b-shadow {
  box-shadow: 2px 8px 8px 0 rgba(29, 35, 41, 0.05);
}

.header-icon {
  height: 25px;
}

.user {
  padding-right: 6px;
  padding-top: 0px;
  padding-bottom: 14px;
  padding-left: 6px;
  margin-right: 3px;
  border: 1px solid;
}
.user-status {
  position: absolute !important;
  right: 72px;
  top: 9px;
}
.user-status .ant-badge-status-text {
  font-size: 10px;
}
.sb-avatar__text {
  border-radius: 2px;
}

// Sample

.samplelist {
  height: 400px;
  padding: 20px;
  overflow-y: scroll;
  border: 1px dashed #cecece;
  background: #fff;
}
.pret-caps {
  font-variant: petite-caps;
}

// Hiring Step Form

.steps-content {
  min-height: 200px;
  margin-top: 16px;

  background-color: #fafafa;
  border: 1px dashed #e9e9e9;
  border-radius: 2px;
}

.steps-action {
  margin-top: 24px;
}
//
.tab-shadow > div {
  box-shadow: 0px 5px 5px #dcd8d8;
}
// A4 Page
.contractform {
  background: rgb(204, 204, 204);
  padding: 10px;
  height: 60vh;
  overflow-y: scroll;
}
page {
  background: white;
  width: 21cm;
  height: 29.7cm;
  display: block;
  margin: 0 auto;
  margin-bottom: 0.5cm;
  overflow: scroll;
  box-shadow: 0 0 0.5cm rgba(0, 0, 0, 0.5);
}
@media print {
  body,
  page {
    margin: 0;
    box-shadow: 0;
  }
}
.cus-btn {
  height: 80px !important;
  width: 70%;
  font-weight: bold !important;
  font-size: 13px !important;
  border: 1px solid !important;
}
.nightsheet-table table {
  margin-top: 10px;
  .ant-table-cell {
    max-width: 80px;
    width: 80px;
    text-transform: uppercase;
    .ant-form-item {
      margin-bottom: 0px;
    }
  }
}
.table1 tbody tr > td:first-child,
.table1 tbody tr > td:first-child:hover {
  background-color: rgb(247, 226, 169);
}
.table2 tbody tr > td:first-child,
.table2 tbody tr > td:first-child:hover {
  background-color: rgb(240, 176, 104);
}
.table3 tbody tr > td:first-child,
.table3 tbody tr > td:first-child:hover {
  background-color: rgb(226, 212, 150);
}
.table4 tbody tr > td:first-child,
.table4 tbody tr > td:first-child:hover {
  background-color: rgb(221, 145, 57);
}
.table5 tbody tr > td:first-child,
.table5 tbody tr > td:first-child:hover {
  background-color: rgb(233, 195, 226);
}
.table6 tbody tr > td:first-child,
.table6 tbody tr > td:first-child:hover {
  background-color: rgb(169, 247, 243);
}
.table7 tbody tr > td:first-child,
.table7 tbody tr > td:first-child:hover {
  background-color: rgb(255, 255, 255);
}
.table8 tbody tr > td:first-child,
.table8 tbody tr > td:first-child:hover {
  background-color: rgb(236, 238, 123);
}
.table9 tbody tr > td:first-child,
.table9 tbody tr > td:first-child:hover {
  background-color: rgb(123, 196, 238);
}
.table10 tbody tr > td:first-child,
.table10 tbody tr > td:first-child:hover {
  background-color: rgb(236, 236, 236);
}
.table11 tbody tr > td:first-child,
.table11 tbody tr > td:first-child:hover {
  background-color: rgb(236, 236, 236);
}
.night-form {
  width: 500px;
}
.sheet-content .steps-content {
  padding: 30px;
}
.audit-box {
  box-shadow: rgb(0 0 0 / 24%) 0px 3px 8px;
}
.MuiButton-text,
.css-1gqyz35-MuiTypography-root,
.MuiTypography-root {
  font-size: 13px !important;
}
.MuiDialog-root {
  z-index: 1 !important;
}
.MuiDialog-paper {
  width: 500px;
}
h6.MuiTypography-subtitle2 {
  color: #fff;
}
.date-col {
  cursor: pointer;
  display: flex;
  padding: 20px;
  width: 100%;
  text-align: center;
  background-color: #fafafa;
  border: 1px solid #dadada;
}
.date-col:hover,
.date-col.active {
  background-color: #dadada;
}
.css-1edlcpn {
  overflow-x: hidden !important;
}
.css-bkrceb-MuiButtonBase-root-MuiPickersDay-root {
  font-size: 12px !important;
}
.css-1ps6pg7-MuiPaper-root {
  border-radius: 2px !important;
  box-shadow: none !important;
}
.bg-cell-light {
  background-color: #eef17eb3;
}
.bg-cell-dark {
  background-color: #71ceccb3;
}
.strip-table tbody tr:nth-child(odd) {
  background-color: rgb(240, 237, 225);
}
.box {
  position: relative;
  background: #ccc;
  border: 1px solid black;
  text-align: center;
  padding: 10px;
  box-sizing: border-box;
  margin-bottom: 10px;
  overflow: hidden;
}

.react-resizable-handle.react-draggable {
  position: absolute;
  width: 20px;
  height: 20px;
  background: url(data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBzdGFuZGFsb25lPSJubyI/Pg08IS0tIEdlbmVyYXRvcjogQWRvYmUgRmlyZXdvcmtzIENTNiwgRXhwb3J0IFNWRyBFeHRlbnNpb24gYnkgQWFyb24gQmVhbGwgKGh0dHA6Ly9maXJld29ya3MuYWJlYWxsLmNvbSkgLiBWZXJzaW9uOiAwLjYuMSAgLS0+DTwhRE9DVFlQRSBzdmcgUFVCTElDICItLy9XM0MvL0RURCBTVkcgMS4xLy9FTiIgImh0dHA6Ly93d3cudzMub3JnL0dyYXBoaWNzL1NWRy8xLjEvRFREL3N2ZzExLmR0ZCI+DTxzdmcgaWQ9IlVudGl0bGVkLVBhZ2UlMjAxIiB2aWV3Qm94PSIwIDAgNiA2IiBzdHlsZT0iYmFja2dyb3VuZC1jb2xvcjojZmZmZmZmMDAiIHZlcnNpb249IjEuMSINCXhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHhtbDpzcGFjZT0icHJlc2VydmUiDQl4PSIwcHgiIHk9IjBweCIgd2lkdGg9IjZweCIgaGVpZ2h0PSI2cHgiDT4NCTxnIG9wYWNpdHk9IjAuMzAyIj4NCQk8cGF0aCBkPSJNIDYgNiBMIDAgNiBMIDAgNC4yIEwgNCA0LjIgTCA0LjIgNC4yIEwgNC4yIDAgTCA2IDAgTCA2IDYgTCA2IDYgWiIgZmlsbD0iIzAwMDAwMCIvPg0JPC9nPg08L3N2Zz4=);
  background-position: bottom right;
  padding: 0 3px 3px 0;
  background-repeat: no-repeat;
  background-origin: content-box;
  box-sizing: border-box;
  cursor: se-resize;
}

.fixed-table {
  margin-left: auto;
  margin-right: auto;
  height: 100%;
  width: 950px;
  table-layout: fixed;
  tr td {
    border: 1px solid black;
    text-align: center;
    padding: 0px;
  }
  tr th {
    border: 1px solid black;
    text-align: center;
    padding: 10px;
  }
}
.event-container {
  position: relative;
  height: 40px;
  span {
    position: absolute;
    background: #bc8f43;
    bottom: 6px;
    font-weight: bold;
    font-size: 8px;
    padding: 0px;
    color: #fff;
    height: 16px;
    text-align: center;
  }
}
.customer-slider {
  margin: 4px 0px 10px;
  width: 950px !important;
  .ant-slider-track {
    height: 12px;
    background-color: #1876d0;
    border-radius: 0px;
  }
  .ant-slider-handle {
    margin-top: 0px;
    background-color: #fff;
    border: solid 2px #1876d0;
  }
  .ant-slider-step {
    width: 950px !important;
    height: 0px;
    background: #fff;
  }
  .ant-slider-rail {
    position: absolute;
    width: 950px !important;
    height: 0px;
    background-color: #fff;
    border-radius: 0px;
  }
}
.modal-customer-slider {
  margin: 10px 0px 10px;
  .ant-slider-track {
    height: 12px;
    background-color: #1876d0;
    border-radius: 0px;
  }
  .ant-slider-handle {
    margin-top: 0px;
    background-color: #fff;
    border: solid 2px #1876d0;
  }
  .ant-slider-step {
    height: 0px;
    background: #fff;
  }
  .ant-slider-rail {
    position: absolute;
    height: 12px;
    background-color: #aaa;
    border-radius: 5px;
  }
}
