@import "~antd/lib/style/themes/default.less";
@import "~antd/dist/antd.compact.less";
@import "./style/main.less";
@primary-color: #b17e21;
@logo-color: #424242;
@body-background: #eff2f5;
@base-color: #fff;

.base-style {
  background-color: @base-color;
}
@layout-header-background: @base-color;
@menu-bg: @base-color;
@layout-header-height: 43px;
@menu-inline-toplevel-item-height: 40px;
@menu-item-height: 35px;

.tab-shadow > div {
  box-shadow: 0px 5px 5px #dcd8d8;
}

.ant-pro-table-list-toolbar-setting-item {
  color: @primary-color;
}

.ant-table.ant-table-bordered > .ant-table-footer {
  padding-top: 10px;
  background: #fff;
  border: none;
}
